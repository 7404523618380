<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="入荷実績修正" />

      <!-- 検索項目-->
      <v-container fluid>
        <v-row class="search-row">
          <!-- 取引先-->
          <div class="search-autocomplete first-search-item">
            <v-text-field
              dense
              readonly
              v-model="suppliersSelected"
              :label="$t('label.lbl_supplier')"
              class="txt-single"
              persistent-hint
              margin-bottom="0"
              pa-3
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 入荷元-->
          <div class="search-autocomplete">
            <v-text-field
              dense
              readonly
              v-model="arrivalSelectedName"
              :label="$t('label.lbl_arrival')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 入荷予定日-->
          <div class="date-style">
            <v-text-field
              readonly
              dense
              v-model="arrivalScheduleDateConv"
              :label="$t('label.lbl_arrivalScheduleDate')"
              class="txt-single"
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 処理区分-->
          <div class="search-autocomplete" style="float: left">
            <v-text-field
              dense
              readonly
              v-model="processDivSelected"
              :label="$t('label.lbl_processingdivision')"
              class="txt-single"
              persistent-hint
              margin-bottom="0"
            ></v-text-field>
          </div>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn first-search-item padding-bottom"></div>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn">
            <div class="btn-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn color="primary" class="api-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <div class="btn-area">
              <!-- 修正登録ボタン-->
              <v-btn color="primary" class="api-btn" value="1" v-on:click="rcvScheAdd">
                {{ $t("btn.btn_fixinsert") }}
              </v-btn>

              <!-- メッセージダイアログ-->
              <ConfirmDialog
                :isShow.sync="ConfirmDialog.isOpen"
                :message="ConfirmDialog.message"
                :okAction="addRcvSch"
                :redMessage="ConfirmDialog.redMessage"
                :screenFlag="ConfirmDialog.screenFlag"
                :changeFlag="ConfirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-container fluid
        ><v-form ref="editedList">
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- ロットヘッダー部分-->
            <template v-slot:[`header.lot`]="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>{{ "説明" }}</span>
              </v-tooltip> </template
            >]
            <!-- 商品コード/品名-->
            <template v-slot:[`item.productCd`]="{ item }">
              <!-- <v-text-field
                v-model="item.productCd"
                :items="productList"
                :hint="checkProductCd(item)"
                persistent-hint
                readonly
              ></v-text-field> -->
              <!-- <div>{{ item.productCnCd }}</div>
              
              <div>{{ item.productName }}</div> -->
              {{ checkProductCd(item) }}
            </template>
            <!-- 入荷予定数-->
            <!-- <template v-slot:[`item.productQty`]="{ item, index }">
              <div id="table-productQty">
                <v-text-field
                  class="input-number"
                  dense
                  readonly
                  outlined
                  v-model="item.productQty"
                  maxlength="10"
                  @change="changeQty(item, index)"
                  :rules="[rules.isNumber]"
                />
              </div>
            </template> -->
            <!-- 入荷実績数-->
            <template v-slot:[`item.actualQuantity`]="{ item, index }">
              <div
                id="table-actualQuantity"
                v-if="item.oriActualQuantity == 0 || item.oriSubtraction == 0"
              >
                {{ item.actualQuantity }}
              </div>
              <div v-else>
                <v-text-field
                  class="input-number"
                  dense
                  outlined
                  v-model="item.actualQuantity"
                  maxlength="10"
                  @change="changeActualQuantity(item, index)"
                  :rules="[rules.isNumber]"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </div>
            </template>
          </v-data-table>
        </v-form>

        <!-- 戻るボタンのダイアログ -->
        <v-dialog v-model="updateDialogMessage" :max-width="800">
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ backMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="updateOk"> OK </v-btn>
              <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :redMessage="infoDialog.redMessage"
        :screenFlag="infoDialog.screenFlag"
        :changeFlag="infoDialog.changeFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
        :secondPageFlag="infoDialog.secondPageFlag"
        :fivePageFlag="infoDialog.fivePageFlag"
        :suppliersSelected="infoDialog.suppliersSelected"
      />
      <v-dialog v-model="dialogLocation" :max-width="800">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import LocationDialog from "../../views/common/Location";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";

export default {
  name: "RcvAchieveUpdate",
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    LocationDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    loadingCounter: 0,
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // ロケーションダイアログ
    dialogLocation: false,
    // 修正登録フラグ
    addFlg: false,
    // 伝票削除フラグ
    delFlg: false,
    // 新製品登録ダイアログ
    dialogNewProduct: false,
    // 新製品コード
    popNewProductCd: "",
    // 新製品名
    popNewProductNm: "",
    // 新商品を選択したインデックス
    newProductSelectedIndex: 0,
    // 一覧index
    editedIndex: -1,
    // 削除リスト
    deleteList: [],
    // バリデーションチェックリスト
    editedList: [],
    // 入荷予定修正削除ダイアログ
    dialogAddProudct: false,
    // 荷主伝票番号
    txt_slipNo: "",
    // 有償/無償
    isPaid: "01",
    // 入荷元SID（一覧返す用）
    fromSidLList: "",
    // 無償理由
    freeReasonSelected: "",
    // 責任
    isRes: "",
    // 入荷先
    arrivalSelected: "",
    // 入荷先名称
    arrivalSelectedName: "",
    // 入荷ステータス
    arrivalStatusSelected: "",
    // 追加レコード用品質区分
    qualityDivAdd: "",
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    // 入荷予定No.
    txt_arrivalScheduleNo: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    // 賞味期限日付キャレンダー
    dateExpire: false,
    // メッセージダイアログ
    dialogMessage: false,
    // 入荷予定日付
    arrivalScheduleDateCal: "",
    //表示用
    arrivalScheduleDateConv: "",
    arrivalScheduleDate: "",
    // フォーカス移動フラグ
    moveFocusFlag: false,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      secondPageFlag: false,
      fivePageFlag: false,
      suppliersSelected: "",
    },
    productListIdx: -1,
    // 入荷予定品目一覧ヘッダ
    headerItems: [
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "4%",
        sortable: false,
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "13%",
        align: "center",
        sortable: false,
      },
      { text: i18n.tc("label.lbl_lot"), value: "lot", width: "7%", align: "center" },

      {
        text: i18n.tc("label.lbl_productQty"),
        value: "productQty",
        width: "9%",
        align: "right",
        sortable: false,
      },
      {
        text: i18n.tc("入荷実績数"),
        value: "actualQuantity",
        width: "9%",
        align: "right",
        sortable: false,
      },
      {
        text: i18n.tc("差"),
        value: "subtraction",
        width: "9%",
        align: "right",
        sortable: false,
      },

      // {
      //   text: i18n.tc("label.lbl_caseSu"),
      //   value: "caseSu",
      //   width: "9%",
      //   align: "center",
      //   class: "asta",
      // },
      // {
      //   text: i18n.tc("label.lbl_ballSu"),
      //   value: "ballSu",
      //   width: "9%",
      //   align: "center",
      //   class: "asta",
      // },
      // {
      //   text: i18n.tc("label.lbl_baraSu"),
      //   value: "baraSu",
      //   width: "9%",
      //   align: "center",
      //   class: "asta",
      // },

      // { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "5%", align: "center" },
    ],
    inputList: [],
    //予定一覧のデータ
    arrivalDataList: [],
    //検索に使う予定一覧のデータ
    arrivalInputlDataList: [],
    // 処理区分
    processDivSelected: "",
    defaultItem: {
      productNm: "",
      productCd: "",
      productAcc: "",
      productQty: "",
      actualQuantity: "",
      subtraction: "",
      afterStock: "",
      caseSu: "",
      ballSu: "",
      baraSu: "",
      lot: "",
      warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
      accInfoList: [],
      accInfos: "",
      isNew: false,
    },
    // 品番プルダウン
    productList: [],
    accHeader: [
      { text: "キー", value: "accSelected", align: "center", width: "20%" },
      { text: "バリュー", value: "accValue", align: "center", width: "70%" },
      { text: "削除", value: "delete", align: "center", width: "10%" },
    ],
    accKeyMst: [],
    accList: [],
    accKeys: [],
    // 無償理由CD一時保存場所
    reasonCdChe: "",
    newProductInfo: {
      text: "新商品コード",
      value: "newProductCd",
    },
    focusIdx: 0,
    location: "",
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
    },
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  created() {
    // 入荷予定日（明細）
    this.arrivalScheduleDate = this.$route.params.arrivalScheduleDate;
    this.arrivalScheduleDateConv = dateTimeHelper
      .convertUTC2JST(this.arrivalScheduleDate)
      .substr(0, 10);
    // 取引先(修正画面表示用)
    this.suppliersSelected = this.$route.params.suppliersSelected;
    // 取引先SID
    this.suppliersNm = this.$route.params.suppliersNm;
    // 入荷元名（明細）
    this.arrivalSelectedName = this.$route.params.arrivalto;
    // 入荷元SID（明細）
    this.fromSid = this.$route.params.fromSid;
    this.arrivalSelected = this.$route.params.arrivalSelected;

    // 処理区分(通常/返品)（明細）
    this.arrivalStatusSelectedList = this.$route.params.processingdivision;
    //  入荷SID
    this.arrSid = this.$route.params.arrSid;
    // 入荷予定日From
    this.arrAchieveDateFrom = this.$route.params.arrAchieveDateFrom;
    // 入荷予定日TO
    this.arrAchieveDateTo = this.$route.params.arrAchieveDateTo;
    //入荷予定を取得
    this.getArrivalList();
    //入荷予定から取得した値をセット
    this.getItems();
  },
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定(出荷予定一覧から値受け取る)
     */
    init() {
      // createdにて実施
    },

    /**
     * 入荷予定修正削除画面：入荷予定の明細と品番品名プルダウン取得にアクセス
     */
    getItems() {
      this.getCdName();
      this.getDetail();
    },

    /**
     * 入荷予定修正削除画面：最新の入荷予定を取得
     */
    getArrivalList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.clientSid = this.suppliersNm;
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.inListSid = this.arrSid;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_INSCHEDULES, config)
          .then((response) => {
            // console.debug("getArrivalList() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // for(){}繰り返し処理を行い、配列を格納する処理を記述する
              this.arrivalDataList = jsonData.resIdv.inSchedules;
              for (let i = 0; i < this.arrivalDataList.length; i++) {
                let data = {
                  // 入荷伝票Sid
                  inListSid: this.arrivalDataList[i].inListSid,
                  // 営業所SID
                  officeSid: this.arrivalDataList[i].officeSid,
                  // 取引先SID
                  clientSid: this.arrivalDataList[i].clientSid,
                  // 入荷伝票No
                  inListNo: this.arrivalDataList[i].inListNo,
                  // 入荷元名
                  fromName: this.arrivalDataList[i].fromName,
                  // 入荷予定日
                  inScheduleDate: this.arrivalDataList[i].inScheduleDate,
                  // 修正区分
                  modifyDiv: this.arrivalDataList[i].modifyDiv,
                  // 有償無償
                  isPaid: this.arrivalDataList[i].freeCostDiv,
                  // 処理区分
                  processDiv: this.arrivalDataList[i].processDiv,
                  // 処理区分名
                  processDivName: this.arrivalDataList[i].processDivName,
                  // 責任区分
                  blameDiv: this.arrivalDataList[i].blameDiv,
                  // 入荷元SID
                  fromSid: this.arrivalDataList[i].fromSid,
                  // TOSID
                  toSid: this.arrivalDataList[i].toSid,
                  // 理由CD
                  reasonCd: this.arrivalDataList[i].reasonCd,
                  // 削除フラグ
                  deleteFlg: this.arrivalDataList[i].deleteFlg,
                  // 予定伝票更新日時
                  updateDatetime: this.arrivalDataList[i].updateDatetime,
                };
                this.arrivalInputlDataList.push(data);
              }

              // 検索条件領域に値を設定
              // 有償無償区分
              this.isPaid = this.arrivalInputlDataList[0].isPaid;
              // 責任区分
              this.isRes = this.arrivalInputlDataList[0].blameDiv;
              // 処理区分名
              this.processDivSelected = this.arrivalInputlDataList[0].processDivName;

              // 入荷元名
              this.arrivalSelectedName = this.arrivalInputlDataList[0].fromName;

              // 無償理由名を取得し、設定
              this.reasonCdChe = this.arrivalInputlDataList[0].reasonCd;
              if (this.reasonCdChe != "" && this.reasonCdChe != null) {
                this.getFreeRea();
              }
              // エラー時
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              // this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.secondPageFlag = false;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getArrivalList() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.secondPageFlag = false;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 入荷予定修正削除画面： 無償理由_コードマスタAPI(GET)
     */
    getFreeRea() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 検索条件などのパラメータを設定する
      config.params.isMst = "0";
      config.params.codeType = "0105";
      config.params.code = this.reasonCdChe;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_CODE, config)
          .then((response) => {
            // console.debug("getFreeRea() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.codeDefinition.forEach((row) => {
                list.push({
                  value: row.codeInfoList[0].codeName,
                });
              });

              // 検索条件の無償理由に設定
              this.freeReasonSelected = list[0].value;

              // エラー時
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              // this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);

              // // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getFreeRea() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 入荷予定修正削除画面：メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷予定修正削除画面：付帯情報ツールチップ表示処理
     */
    setItemToList() {
      // 付帯情報詳細処理
      const accInfos = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfos.push(this.accList[i].accSelectedNm + ":" + this.accList[i].accValue);
      }
      return accInfos.join("\n");
    },

    /**
     * 入荷予定修正削除画面：付帯情報値変換処理(項目)
     */
    setItemTo() {
      // 付帯情報詳細処理
      const accInfoItem = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoItem.push(this.accList[i].accSelectedNm);
      }
      return accInfoItem;
    },

    /**
     * 入荷予定修正削除画面：付帯情報値変換処理(内容)
     */
    setItemCon() {
      // 付帯情報詳細処理
      const accInfoCon = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoCon.push(this.accList[i].accValue);
      }
      return accInfoCon;
    },

    /**
     * 入荷予定修正削除画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      this.deleteList.push(this.inputList[this.editedIndex]);
      this.inputList.splice(this.editedIndex, 1);

      // 削除後、No修正。
      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
    },

    /**
     * 入荷予定修正削除画面：商品コードリストボックス取得処理
     */
    checkProductCd(value) {
      for (var i = 0; i < this.productList.length; i++) {
        if (this.productList[i].value == value.productCd) {
          return this.productList[i].name;
        }
      }
    },

    /**
     * 明細の選択した商品コードに紐づく数量を返却するメソッド
     */
    getSelectedProductQuantity(index) {
      // 返却値
      let returnQuantity = 0;

      // inputListの商品コードを取得
      let inputListProductCd = this.inputList[index].productCd;

      if (!inputListProductCd) {
        // 商品コードが取得できた場合場合
        // 選択した商品コードがproductListに何番目に入っているかを調べる。
        let selectedProductListIndex = this.productList.findIndex(
          ({ value }) => value === inputListProductCd
        );

        if (0 <= selectedProductListIndex) {
          // 選択している品番の数量を取得する。
          returnQuantity = this.productList[selectedProductListIndex].quantity;
        } else {
          // 処理なし
        }
      } else {
        // 処理なし
      }
      return returnQuantity;
    },

    /**
     * 入荷予定修正削除画面：ケース数計算処理
     */
    setHintCase(value, index) {
      var proQty = commonUtil.zen2han(value.productQty).replaceAll(",", "");

      // 選択した商品コードに紐づく数量を取得する。
      let selectedProductQuantity = this.getSelectedProductQuantity(index);

      // 取得した商品コードに紐づく数量は割り算で使うので0以下の場合は1にエラー回避のため変換する。
      if (selectedProductQuantity <= 0) {
        selectedProductQuantity = 1;
      } else {
        // 処理なし
      }
      if (!isNaN(Number(proQty))) {
        return commonUtil.formatToCurrency(Math.ceil(proQty / selectedProductQuantity));
      } else {
        return "";
      }
    },

    /**
     * 入荷予定修正削除画面：ボール数計算処理
     */
    setHintBall(value, index) {
      var proQty = commonUtil.zen2han(value.productQty).replaceAll(",", "");

      // 選択した商品コードに紐づく数量を取得する。
      let selectedProductQuantity = this.getSelectedProductQuantity(index);

      // 取得した商品コードに紐づく数量は割り算で使うので0以下の場合は1にエラー回避のため変換する。
      if (selectedProductQuantity <= 0) {
        selectedProductQuantity = 1;
      } else {
        // 処理なし
      }

      if (!isNaN(Number(proQty))) {
        return commonUtil.formatToCurrency(Math.ceil(proQty / selectedProductQuantity));
      } else {
        return "";
      }
    },

    /**
     * 入荷予定修正削除画面：バラ数計算処理
     */
    setHintBara(value, index) {
      var proQty = commonUtil.zen2han(value.productQty).replaceAll(",", "");
      // 選択した商品コードに紐づく数量を取得する。
      let selectedProductQuantity = this.getSelectedProductQuantity(index);

      // 取得した商品コードに紐づく数量は割り算で使うので0以下の場合は1にエラー回避のため変換する。
      if (selectedProductQuantity <= 0) {
        selectedProductQuantity = 1;
      } else {
        // 処理なし
      }

      if (!isNaN(Number(proQty))) {
        return commonUtil.formatToCurrency(Math.ceil(proQty / selectedProductQuantity));
      } else {
        return "";
      }
    },

    /**
     * 入荷予定修正削除画面：戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 入荷予定修正削除画面：一覧画面へ渡す値を設定
     */
    updateOk() {
      this.updateDialogMessage = false;

      this.$router.push({
        name: appConfig.MENU_ID.P_RCV_ACTUAL_LIST,
        params: {
          suppliersSelected: this.suppliersNm,
          arrivalSelected: this.arrivalSelected,
          // txt_slipNo: this.inListNo,
          arrAchieveDateFrom: this.arrAchieveDateFrom,
          arrAchieveDateTo: this.arrAchieveDateTo,
          arrivalStatusSelected: this.arrivalStatusSelectedList,
        },
      });
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 入荷予定修正削除画面：行追加処理
     */
    add() {
      this.focusIdx = this.inputList[this.inputList.length - 1].No;
      for (var i = 0; i < 10; i++) {
        this.defaultItem.No = this.focusIdx + i + 1;
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      // フォーカス移動フラグをONに
      this.moveFocusFlag = true;
    },

    /**
     * 入荷予定修正削除画面：入荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      // カンマを除去して数値項目に変換
      var proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");

      // 各数量項目の計算処理
      if (!isNaN(Number(proQty))) {
        // カンマ編集して数量にセット
        this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
      }
    },

    /**
     * 入荷実績修正画面：入力カンマ処理 入荷実績数
     */
    changeActualQuantity(val, index) {
      var ActualQuantity = commonUtil.zen2han(val.actualQuantity).replaceAll(",", "");
      var inScheduleQuantity = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      if (!isNaN(Number(ActualQuantity))) {
        this.inputList[index].subtraction = inScheduleQuantity - ActualQuantity;
        this.inputList[index].actualQuantity = commonUtil.formatToCurrency(Number(ActualQuantity));
        this.inputList[index].subtraction = commonUtil.formatToCurrency(
          Number(this.inputList[index].subtraction)
        );
      }
    },

    /**
     * 入荷予定修正削除画面：閉じる処理
     */
    popNewProductClose() {
      this.dialogNewProduct = false;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：閉じる処理
     */
    close() {
      this.dialogAddProudct = false;
      this.editedIndex = -1;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報/ロケーション予約POPUP処理
     */
    openAccData(item) {
      // 選択した明細のインデックスを取得
      this.editedIndex = this.inputList.indexOf(item);

      // indexから付帯情報を取得する。
      let accInfoList = this.inputList[this.editedIndex].accInfoList;

      // 付帯情報を表示用付帯情報に設定する。
      this.accList = accInfoList;

      // ロケを画面標示用に設定する。
      if (this.inputList[this.editedIndex].location) {
        let location = this.inputList[this.editedIndex].location;
        this.location = location;
      } else {
        this.location = "";
      }

      // マスタを取得する。
      this.getMaster(item);

      this.dialogAddProudct = true;
    },
    /**
     * 付帯情報/ロケーション予約POPUP：行削除処理
     */
    deleteAccItem(item) {
      this.editedIndex = this.accList.indexOf(item);
      this.accList.splice(this.editedIndex, 1);
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行追加処理
     */
    addAccItem() {
      this.accList.push({ accSelected: "", accValue: "" });
    },

    /**
     * 付帯情報/ロケーション予約POPUP：登録処理
     */
    save() {
      // ツールチップの値
      var accInfos = this.setItemToList();
      // 付帯情報の名前のリスト
      var accInfoItem = this.setItemTo();
      // 付帯情報の値のリスト
      var accValue = this.setItemCon();

      // 付帯情報リストをクリアする
      this.accList.splice(0);

      // 付帯情報の名前と値のリストを作成する
      let ancillaryInfoList = [];

      // 付帯情報分リストを回す
      for (var i = 0; i < accInfoItem.length; i++) {
        // 付帯情報保持データ
        let ancillaryInfo = { accSelected: "", accSelectedNm: "" };

        // 名前
        ancillaryInfo.accSelected = accInfoItem[i];
        ancillaryInfo.accSelectedNm = accInfoItem[i];

        // 値
        ancillaryInfo.accValue = accValue[i];

        // 付帯情報の名前と値のリストに追加
        ancillaryInfoList.push(ancillaryInfo);
      }

      // 画面表示用付属情報（ツールチップ）
      this.inputList[this.editedIndex].accInfos = accInfos;

      // 付帯情報を設定
      this.inputList[this.editedIndex].accInfoList = ancillaryInfoList;

      // 値渡し用ロケリスト
      this.inputList[this.editedIndex].location = this.location;
      this.close();
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報リストボックス取得処理
     */
    changeAcc(value, item) {
      for (var i = 0; i < this.accKeys.length; i++) {
        if (this.accKeys[i].value == value) {
          item.accSelectedNm = this.accKeys[i].text;
          break;
        }
      }
    },

    /**
     * 付帯情報/ロケーション予約POPUP：マスタから取得する付帯情報
     */
    getAccMst() {
      this.accKeys.push({ text: "材料", value: "material", required: "0" });
      this.accKeys.push({ text: "備考", value: "bigo", required: "0" });
    },

    /**
     * ロケーション予約画面処理
     */
    addLocation() {
      if (this.inputList[this.editedIndex].warehouse == {}) {
        // ロケ登録なし
        this.locInfo.warehouse = "";
        this.locInfo.location = "";
        this.locInfo.productCd = "";
        this.locInfo.qty = 0;
        this.locInfo.init = true;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      } else {
        // ロケ登録あり
        this.locInfo.location = this.inputList[this.editedIndex].location;
        this.locInfo.productCd = this.inputList[this.editedIndex].productCd;
        this.locInfo.init = false;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      }
      this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      this.locInfo.locProductCd = this.inputList[this.editedIndex].productCd;
      this.dialogLocation = true;
    },

    /**
     * 新製品登録POPUP：新製品登録処理
     */
    changeProductNm(value, item, index) {
      if (value == "product0") {
        this.dialogNewProduct = true;
        this.newProductSelectedIndex = index;
      } else {
        for (var i = 0; i < this.productList.length; i++) {
          if (this.productList[i].value == value) {
            item.productNm = this.productList[i].name;
            item.isNew = this.productList[i].isNew;

            break;
          }
        }
      }
    },

    /**
     * 新製品登録POPUP：登録処理
     */
    popNewProductSave() {
      this.productList.push({
        text: this.popNewProductCd + "（" + this.popNewProductNm + "）",
        value: this.popNewProductCd,
        name: this.popNewProductNm,
        code: this.popNewProductCd,
        isNew: true,
      });
      this.dialogNewProduct = false;
      this.addCdName();

      this.productCd = this.popNewProductCd;
      // 仮登録を選択した部分の値を変更する。（変更しないと仮登録のままなので）
      this.inputList[this.newProductSelectedIndex].productCd = this.popNewProductCd;
      // 仮登録のものの背景を黄色くする。
      this.inputList[this.newProductSelectedIndex].isNew = true;
      // 値をクリアする。
      this.popNewProductNm = "";
      this.popNewProductCd = "";
    },

    /**
     * ロケーション予約画面：登録後処理
     */
    saveLocation(data) {
      // 倉庫名
      this.warehouse = data;
      // 画面表示用ロケーション(ロケーションNo + 倉庫名称表示用)
      this.location = data.location + "（" + data.warehouseNameShort + "）";
      // ロケーションSID
      this.locationSid = data.locationSid;
      // ダイアログを閉じる
      this.dialogLocation = false;
    },

    /**
     * 入荷予定修正削除画面：修正登録ボタン押下
     */
    rcvScheAdd() {
      this.addFlg = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;

      this.loadingCounter++;
    },

    /**
     * 入荷予定修正削除画面：伝票削除ボタン押下
     */
    rcvScheDel() {
      this.delFlg = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_001_C");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;

      this.loadingCounter++;
    },

    /**
     * 入荷予定修正削除画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    /**
     * 入荷予定修正削除画面： 付属情報_商品付属マスタAPI(GET)
     */
    getMaster(item) {
      this.editedIndex = this.inputList.indexOf(item);
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.itemCommonSid = this.inputList[this.editedIndex].productCd;
      config.params.isMst = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_ITEMSINDIVI, config)

          .then((response) => {
            // console.debug("getMaster(item) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.individuals.forEach((row) => {
                list.push({
                  text: row.individualLanguage[0].subInformation,
                  value: row.individualLanguage[0].subInformation,
                  required: "1",
                });
              });
              this.accKeys = list;

              // エラー時
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              // this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);

              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.secondPageFlag = false;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getMaster(item) Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.secondPageFlag = false;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 入荷予定修正削除画面： 品番/品名_業務利用商品API(GET)
     */
    getCdName() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 検索条件などのパラメータを設定する
      config.params.clientSid = this.suppliersNm;
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);

      // console.debug("getCdName() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_ITEMBIZ, config)
          .then((response) => {
            // console.debug("getCdName() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [
                {
                  text: i18n.tc("label.lbl_newProductAdd"),
                  value: "product0",
                  name: i18n.tc("label.lbl_newProductAdd"),
                  isNew: true,
                },
              ];
              jsonData.resIdv.ItemsBizUse.forEach((row) => {
                list.push({
                  text: `${row.itemCd}` + `（${row.itemName}）`,
                  value: row.itemStandardSid,
                  name: row.itemName,
                  code: row.itemCd,
                  quantity: row.inboundUnitQuantity,
                  isNew: false,
                });
              });

              this.productList = list;

              // エラー時
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              // this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.secondPageFlag = false;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getCdName() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.secondPageFlag = false;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 入荷予定修正削除画面：入荷予定API（POST）
     */
    addRcvSch() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();

      // 画面ID
      body.reqCom.reqComComponentId = i18n.tc("label.recieveAchieveUpdateDeletepc");

      // 入荷予定明細List
      var bodyList = [];

      // 画面上に表示されている明細
      for (var i = 0; i < this.inputList.length; i++) {
        //productCdは商品コード 商品コードが空ではない場合
        if (this.inputList[i].productCd != "" && this.inputList[i].actualQuantity != "0") {
          //商品コードがあるもの
          var inputMap = {};

          // 品番
          // for (var m = 0; m < this.productList.length; m++) {
          //   //商品コードの数だけ回す
          //   if (this.productList[m].value == this.inputList[i].productCd) {
          //     //品番
          //     inputMap.itemCd = this.productList[m].code;
          //   }
          // }
          inputMap.opeDispName = i18n.tc("label.lbl_recieveAchieveUpdateDeletepc");
          // officeSid
          inputMap.officeSid = this.arrivalInputlDataList[0].officeSid;
          // clientSid
          inputMap.clientSid = this.arrivalInputlDataList[0].clientSid;
          // fromSid
          inputMap.fromSid = this.arrivalInputlDataList[0].fromSid;
          // toSid
          inputMap.toSid = this.arrivalInputlDataList[0].toSid;
          // procCd
          inputMap.procCd = "100";
          // itemStandardSid
          inputMap.itemStandardSid = this.inputList[i].productCd;
          // itemCd
          inputMap.itemCd = this.inputList[i].itemCd;
          // inListSubSid
          inputMap.inListSubSid = this.inputList[i].inListSubSid;
          // afterStock
          inputMap.afterStock = this.inputList[i].actualQuantity.replaceAll(",", "");
          bodyList.push(inputMap);
        }
      }
      body.reqIdv.opeHists = bodyList;
      // console.debug("addRcvSch() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_OPEHIST, body, appConfig.APP_CONFIG)

          .then((response) => {
            // console.debug("addRcvSch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.secondPageFlag = false;
              this.infoDialog.firstPageFlag = false;
              this.infoDialog.thirdPageFlag = false;
              this.infoDialog.fivePageFlag = true;

              // ボタン識別フラグ
              this.addFlg = false;
              this.delFlg = false;

              resolve(response);

              // エラー時
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();

              // ボタン識別フラグ
              this.addFlg = false;
              this.delFlg = false;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();

            // ボタン識別フラグ
            this.addFlg = false;
            this.delFlg = false;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 入荷予定修正削除画面：業務利用商品API（POST）
     */
    addCdName() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqIdv.reqComComponentId = appConfig.SCREEN_ID.P_RCV_004;

      body.reqIdv.clientSid = this.suppliersSelected;
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      body.reqIdv.itemCd = this.popNewProductCd;
      body.reqIdv.itemName = this.popNewProductNm;
      // console.debug("addCdName() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_ITEMBIZ, body, appConfig.APP_CONFIG)

          .then((response) => {
            // console.debug("addCdName() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.popNewProductNm = jsonData.resIdv.ItemsBusinessUse.itemName;
              this.popNewProductCd = jsonData.resIdv.ItemsBusinessUse.itemCd;
              this.popNewProductSid = jsonData.resIdv.ItemsBusinessUse.itemCommonSid;
              // エラー時
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              //this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);

              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
            }
            this.infoDialog.title = "結果";
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.secondPageFlag = false;
            this.infoDialog.firstPageFlag = true;
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.secondPageFlag = false;
            this.infoDialog.firstPageFlag = true;
            reject(resoleve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 入荷実績修正削除：明細API接続
     */
    getDetail() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 入荷予定SID
      config.params.inListSid = this.arrSid;
      // 工程コード
      config.params.procCd = "100";

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      // console.debug("getDetail() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_INDIFFERENC, config)
          .then((response) => {
            // console.debug("getDetail() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const apiData = jsonData.resIdv.inDifference;

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              for (var j = 0; j < apiData.length; j++) {
                // API結果：入荷予定一覧明細
                let apiDataItem = apiData[j];
                // API結果保持変数
                let inputListItem = {};
                inputListItem.No = j + 1;
                inputListItem.inListSubSid = apiDataItem.inListSubSid;
                inputListItem.inListSubNo = apiDataItem.inListSubNo;
                // 品番
                inputListItem.itemCd = apiDataItem.itemCd;
                // 品名
                inputListItem.itemName = apiDataItem.itemName;
                // 商品コード/品名画面表示部分を設定
                inputListItem.productCd = apiDataItem.itemStandardSid;
                // 商品SID
                inputListItem.itemStandardSid = apiDataItem.itemStandardSid;
                inputListItem.lot = apiDataItem.lotNo; // ロット
                let productQtyNum = apiDataItem.inScheduleQuantity;
                // 入荷予定数(必須)
                inputListItem.productQty = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );
                // 入荷実績数
                let actualQuantity = apiDataItem.actualQuantity;
                inputListItem.actualQuantity = String(
                  commonUtil.formatToCurrency(Number(actualQuantity))
                );
                inputListItem.oriActualQuantity = String(
                  commonUtil.formatToCurrency(Number(actualQuantity))
                );
                // 差
                let subtraction = apiDataItem.subtraction;
                inputListItem.subtraction = String(
                  commonUtil.formatToCurrency(Number(subtraction))
                );
                inputListItem.oriSubtraction = String(
                  commonUtil.formatToCurrency(Number(subtraction))
                );
                // ケース数
                let caseNum = apiDataItem.caseQuantity;
                inputListItem.caseSu = String(commonUtil.formatToCurrency(Number(caseNum)));
                // ボール数
                let ballNum = apiDataItem.ballQuantity;
                inputListItem.ballSu = String(commonUtil.formatToCurrency(Number(ballNum)));
                // バラ数
                let baraNum = apiDataItem.pieceQuantity;
                inputListItem.baraSu = String(commonUtil.formatToCurrency(Number(baraNum)));
                // 明細更新日時
                inputListItem.updateDatetime = apiDataItem.updateDatetime;
                // 品質区分
                inputListItem.qualityDiv = apiDataItem.qualityDiv;
                // 品質区分名
                inputListItem.qualityDivName = apiDataItem.qualityDivName;
                // InputListに格納
                this.inputList.push(inputListItem);
              }

              // 検索条件領域に値を設定
              // 品質区分名
              this.arrivalStatusSelected = this.inputList[0].processDiv;

              // 追加行の品質区分
              this.qualityDivAdd = this.inputList[0].qualityDiv;
              // エラー時
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              // this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);

              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.secondPageFlag = false;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getDetail() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.secondPageFlag = false;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    calcSubtraction() {
      this.subtraction = this.inScheduleQuantity - this.actualQuantity;
      alert(this.subtraction);
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.padding-bottom {
  margin-bottom: 20px;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
</style>
